(function (process,global,Buffer,__argument0,__argument1,__argument2,__argument3,__filename,__dirname){
window.isMobile = ( /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) );
window.isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
window.isAndroid = /Android/.test(navigator.userAgent) && !window.MSStream;

window.requestAnimFrame = (function(){
  return  window.requestAnimationFrame       ||
		  window.webkitRequestAnimationFrame ||
		  window.mozRequestAnimationFrame    ||
		  function( callback ){
			window.setTimeout(callback, 1000 / 60);
		  };
})();

// -~-~-~-~-~-~-~-~-~-~-~-~-~-~-~-~-~-~-~-~
var spec3D = require('./ui/spectrogram');
// -~-~-~-~-~-~-~-~-~-~-~-~-~-~-~-~-~-~-~-~

$(function(){
    var $SpecNavigation = $('.spectrogram-section');
    var Pages = [];

//    $(window).on('load',function(){
//        var wHeight = $(window).height();
//        if ($SpecNavigation.length > 0) {            
//            $SpecNavigation.each(function (Key, Value) {
//                //alert($(Value).attr('id'));
//                Pages[Key] = [];
//                Pages[Key]['id'] = $(Value).attr('id');
//                Pages[Key]['offset'] = $(Value).position().top;
//                Pages[Key]['height'] = ($(Value).position().top + $(Value).outerHeight());
//                Pages[Key]['top'] = (Pages[Key]['offset'] - (wHeight / 2));
//                Pages[Key]['bottom'] = ((Pages[Key]['offset'] + Pages[Key]['height']) - (wHeight / 2));
//            });
//
//        }
//
//    });
//
//    $(window).on('resize',function() {
//        var wHeight = $(window).height();
//        var wWidth = $(window).width();
//        if ($SpecNavigation.length > 0) {
//            $SpecNavigation.each(function (Key, Value) {
//                Pages[Key] = [];
//                Pages[Key]['id'] = $(Value).attr('id');
//                Pages[Key]['offset'] = $(Value).position().top;
//                Pages[Key]['height'] = ($(Value).position().top + $(Value).outerHeight());
//                Pages[Key]['top'] = (Pages[Key]['offset'] - (wHeight / 2));
//                Pages[Key]['bottom'] = ((Pages[Key]['offset'] + Pages[Key]['height']) - (wHeight / 2));
//            });
//        }
//    });
//
//    $('#spectrogram').scroll(function () {
//        var Scroll = $(this).scrollTop();
//        if (Pages.length > 0) {
//            for (var I = 0; I < Pages.length; I++) {
//                var $id = Pages[I]['id'];
//                var $offset = Pages[I]['offset'];
//                var $spectrogramSet = $('#' + $id).find('.spectrogram-area');
//                var $spectrogramOffset = parseInt($offset + $spectrogramSet.position().top);
//                var $height = Pages[I]['height'];
//                var $top = Pages[I]['top'];
//                var $bottom = Pages[I]['bottom'];
//                if (Scroll <= $bottom && Scroll >= $top) {
//                     $('.spectrogram-set').css({top : $spectrogramOffset });
//                }             
//                if (Scroll <= $height && Scroll >= $offset) {
//                    if($('#' + $id + '-tips').length > 0){
//                        $('#' + $id + '-tips').css({top : parseInt($offset + 300) });
//                        $('#' + $id + '-tips').fadeIn();
//                    }
//                    $('.spectrogram-set').css({top : $spectrogramOffset });
//                } 
//            }
//        }
//    });
    
    $('.close-tip').click(function(){
        $(this).parent().remove();
        return false;    
    });
    
    $('.listen-tip').click(function(){
        var href = $(this).attr('href');
        $(href).trigger('click');
        return false;    
    });

	var parseQueryString = function(){
		var q = window.location.search.slice(1).split('&');
		for(var i=0; i < q.length; ++i){
			var qi = q[i].split('=');
			q[i] = {};
			q[i][qi[0]] = qi[1];
		}
		return q;
	}

	var startup = function (){
        var source = null; // global source for user dropped audio

		window.parent.postMessage('ready','*');

		var sp = spec3D;
		sp.attached();
		// --------------------------------------------
		$('.music-box__tool-tip').hide(0);
		$('#loadingSound').hide(0);

		$('.spectrogram-button').click(function(e){
			sp.startRender();
			
			var wasPlaying = sp.isPlaying();
			sp.stop();
			sp.drawingMode = false;
			
			if ($(this).attr('data-top-mix') !== undefined || $(this).attr('data-bottom-mix') !== undefined) {
                var mixedSrc = '';
                var mixedName = '';
                if($(this).attr('data-top-mix') !== undefined){
                    if($(this).hasClass('selected')){
                       if($('[data-bottom-mix].selected').length > 0){
                            mixedSrc = $('[data-bottom-mix].selected').attr('data-src');
                            mixedName = $('[data-bottom-mix].selected').attr('data-name');                            
                        } 
                    }
                    else{
                        $('[data-top-mix].spectrogram-button').removeClass('selected');
                        $('#spectrogram-section-1').find('.spectrogram-button').removeClass('selected');
                        $('#spectrogram-section-2').find('.spectrogram-button').removeClass('selected');
                        if($('[data-bottom-mix].selected').length > 0){
                            var dataTop = $(this).attr('data-top-mix');
                            var NameTop = $(this).attr('data-name');
                            var dataBottom = $('[data-bottom-mix].selected').attr('data-bottom-mix');
                            var NameBottom = $('[data-bottom-mix].selected').attr('data-name');
                            mixedSrc = '/assets/sounds/spectrogram/' + dataTop + '_' + dataBottom + '.mp3';
                            mixedName = NameTop + ' ' + NameBottom;

                        }
                    }
                }                 
                else if($(this).attr('data-bottom-mix') !== undefined){
                    if($(this).hasClass('selected')){
                       if($('[data-top-mix].selected').length > 0){
                            mixedSrc = $('[data-top-mix].selected').attr('data-src');
                            mixedName = $('[data-top-mix].selected').attr('data-name');                            
                        } 
                    }
                    else{
                        $('[data-bottom-mix].spectrogram-button').removeClass('selected');
                        $('#spectrogram-section-1').find('.spectrogram-button').removeClass('selected');
                        $('#spectrogram-section-2').find('.spectrogram-button').removeClass('selected');
                        if($('[data-top-mix].selected').length > 0){
                            var dataBottom = $(this).attr('data-bottom-mix');
                            var NameBottom = $(this).attr('data-name');                            
                            var dataTop = $('[data-top-mix].selected').attr('data-top-mix');
                            var NameTop = $('[data-top-mix].selected').attr('data-name');
                            mixedSrc = '/assets/sounds/spectrogram/' + dataTop + '_' + dataBottom + '.mp3'; 
                            mixedName = NameTop + ' ' + NameBottom;
                        }
                    }
                }
                
                if(mixedSrc == '' && !$(this).hasClass('selected')) {
                    mixedSrc = $(this).attr('data-src'); 
                    mixedName = $(this).attr('data-name');                      
                }
                if($(this).hasClass('selected')){
                    $(this).removeClass('selected');
                }
                else{
                    $(this).addClass('selected');                
                }
                if(mixedSrc){  
                    sp.loopChanged( true );
                    $('#loadingMessage').text(mixedName);
                    sp.play(mixedSrc);
                }
            }
            else if ($(this).attr('data-src') !== undefined) {
                if($(this).hasClass('selected')) {
                    $('.spectrogram-button').removeClass('selected'); 
                }else{
                    $('.spectrogram-button').removeClass('selected'); 
                    $(this).addClass('selected');
                    sp.loopChanged( true );
                    $('#loadingMessage').text($(this).attr('data-name'));
                    sp.play($(this).attr('data-src'));
                }
            }
			
		});
        
        
                       
		
		var killSound = function(){
			sp.startRender();
			var wasPlaying = sp.isPlaying();
			sp.stop();
			sp.drawingMode = false;
			$('.spectrogram-button').removeClass('selected'); 
		}

		window.addEventListener('blur', function() {
		   killSound();
		});
		document.addEventListener('visibilitychange', function(){
		    killSound();
		});
        
        $('.navbar-nav li a, button.move-to-next, button.move-to-previous, button.move-to-next-names').on('click', function(e) {
            killSound();
        });
        
	};

	var elm = $('#iosButton');
	if(!window.isIOS){
		elm.addClass('hide');
		startup();
    console.log(2);
	}else{
		window.parent.postMessage('loaded','*');
		elm[0].addEventListener('touchend', function(e){
			elm.addClass('hide');
			startup();
		},false);
	}
});
}).call(this,require("km4Umf"),typeof self !== "undefined" ? self : typeof window !== "undefined" ? window : {},require("buffer").Buffer,arguments[3],arguments[4],arguments[5],arguments[6],"/fake_5005ac5e.js","/")